<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Financial Accounts</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financials' }"
              >Financial</router-link
            >
          </li> 
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financial-Accounts-List' }"
              >Accounts</router-link
            >
          </li>
          <li class="breadcrumb-item active">View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Financial Account Details</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Type</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_account.type }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_account.name }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">ID Number</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_account.id_number }}
                </label>
              </div>

              <div class="form-group row" v-if="financial_account.currency">
                <label class="col-md-3 col-form-label">Currency</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_account.currency.name }} [{{ financial_account.currency.abbreviation }}]
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Branch Name</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_account.branch_name }}
                </label>
              </div>

             <div class="form-group row">
                <label class="col-md-3 col-form-label">Phone</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_account.phone }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Email</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_account.email }}
                </label>
              </div>

             <div class="form-group row">
                <label class="col-md-3 col-form-label">Address</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_account.address }}
                </label>
              </div>
 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFinancialAccounts from "@/composables/financial_accounts";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const { getFinancialAccount, financial_account } = useFinancialAccounts();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("view_financial_accounts"))
        router.push({
          name: "401",
        });

      await getFinancialAccount(props.id);
    });

    return {
      financial_account,
    };
  },
};
</script>